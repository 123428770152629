@import "./colors.scss";
@import "react-redux-toastr/src/styles/index";

a {
  text-decoration: none;
}
html,
body {
  height: 100%;
  min-height: 100%;
}
#root {
  height: 100%;
}
body {
  background-color: $background;
  height: 100%;
}
.anticon.anticon-more.ant-dropdown-trigger {
  padding: 0.25rem 0.5rem;
  cursor: pointer;
}
label {
}
.filterCont {
  display: flex;
  align-items: flex-end;
  gap: 1rem;
  padding: 1rem 1.3rem;
  margin: 0 2rem;
  box-shadow: $shadow;
  border-radius: 0.428rem;
  background: white;
  margin-bottom: 1rem;
}

// table.custom-table {
//   border-collapse: separate !important;
//   border-spacing: 0 10px;
//   width: 100%;
//   padding: 0 1px;
//   // border: 1px solid $borderColor;

//   & th {
//     padding: 1.3rem;
//     position: sticky;
//     top: 0;
//     background-color: $primary;
//     color: #fff;
//     font-size: 1rem;
//     text-align: center !important;
//     white-space: nowrap;
//   }

//   & td {
//     padding: 1rem;
//     text-align: center;
//     color: $textPrimary;
//     font-size: 0.9rem;
//   }

//   & tr {
//     background-color: white;
//     padding: 0.5rem;
//     outline: thin solid $borderColor;
//   }
// }

input.custom-checkbox {
  padding: 1rem;
}

.custom-table-panel-container {
  min-height: 360px;
}

.rc-virtual-list-scrollbar-thumb {
  background: #ababab !important;
}

.custom-table {
  &__table-actions {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__action-icon {
    margin: 0 1rem;
    height: 18px;
    &:hover {
      cursor: pointer;
    }
    & svg {
      fill: $primaryDark;
    }
    &:last-of-type {
      margin-right: 0;
    }

    &--hidden {
      visibility: hidden;
    }
  }

  & td {
    // white-space: nowrap;

    &.sticky-action {
      position: sticky;
      right: 0;
      box-shadow: $shadowLow;
      background-color: #fff;
    }
  }

  & th {
    &.sticky-action {
      position: sticky;
      right: 0;
      box-shadow: $shadowLow;
    }
  }
}

// Global styles for pagination component
.pagination {
  list-style-type: none;
  display: inline-flex;
  padding: 0;
  margin: 0;
  border: 1px solid $borderColor;
  border-radius: 4px;
  & li {
    padding: 0.5rem 1rem;
    background-color: #fff;
    &:hover {
      background-color: $highlight;
      cursor: pointer;
    }

    & a {
      color: $textPrimary;
    }
    border-right: 1px solid $borderColor;
    &:last-of-type {
      border-right: none;
    }

    &.disabled {
      pointer-events: none;
     
      & a {
        color: $textSecondary;
      }
    }

    &.active {
      pointer-events: none;
      background-color: $primary;
      & a {
        color: #fff;
      }
    }
  }
}

.custom-form-modal {
  padding: 16px 24px;
  transition: all 0.2s;
}

.custom-form-action {
  display: flex;
  // justify-content: space-evenly;
  // margin-top: 1rem;
  // flex-direction: flex-end;
  justify-content: flex-end;
  & button {
    margin: 0 0.5rem;
  }
}

.ant-scrolling-effect {
  overflow: auto !important;
}

.custom-form-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
}

.oyelabs__home-wrapper[dir="rtl"] {
  & .custom-menu {
    & header {
      & div {
        margin-right: 0;
        margin-left: 1.5rem;
        &:last-of-type {
          margin-left: 0;
        }
      }
    }

    & section {
      & button {
        margin-left: 0;
        margin-right: 1.5rem;
      }
    }
  }

  & .custom-form-group,
  .custom-form-item {
    padding-right: 0;
    padding-left: 1rem;
  }
}

.custom-menu {
  display: flex;
  // justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: center;
  // padding: 24px;
  margin: 1.5rem;
  // margin-bottom: 0;
  // background-color: #fff;
  & header {
    display: flex;
    & div {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 1.5rem;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
  & section {
    display: flex;
    justify-content: flex-end;
    & button {
      margin-left: 1.5rem;

      // &:-of-type {
      //   margin-right: 0;
      // }
    }
  }
}

.custom-menu.single {
  margin: 32px;
  margin-top: 0;
}

.custom-section {
  display: flex;
  flex-direction: column;
  // padding: 16px 24px;
  margin: 32px;
  margin-top: 0;
  border-radius: 5.992px;
  overflow: hidden;
  margin-bottom: 0;
  background-color: #fff;
  box-shadow: $shadow;
  // height: 100%;
}

.custom-section.single {
  // padding: 24px;
  // margin: 2rem;
  // margin: 24px;
  margin: 0;
  padding: 32px;
  padding-top: 0;
  background-color: transparent;
  box-shadow: none;
  & .custom-menu {
    background-color: #fff;
    border-radius: 5.992px;
    margin: 0;
    padding: 32px;
    margin-bottom: 24px;
    box-shadow: $shadow;
  }
}

.custom-form-group .custom-form-item {
  padding-right: 1rem;
  // justify-content: space-between;
  // & .ant-input-password {
  //   border: none;
  //   border-bottom: 1px solid #d9d9d9;
  //   padding: 4.5px 0;
  //   outline: none;
  // }
  &.single {
    padding-bottom: 0;
  }
  &:last-of-type {
    padding-right: 0;
  }
}

.custom-trans-fix {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.custom-form-title {
  font-size: 1.2rem;
  // border-bottom: 1px solid $borderColor;
  font-weight: bold;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  flex: 1;
}
.custom-form-item {
  display: flex;
  flex-direction: column;
  // padding-right: 1rem;
  padding-bottom: 1rem;
  &.single {
    padding-bottom: 0;
  }
  position: relative;
  flex: 1;
  // width: 50%;
  & > label {
    // color: #6e7484;
    // font-weight: bold;
    // white-space: nowrap;
  }

  &--radio {
    display: flex;
    justify-content: space-between;
  }

  & input {
    // border: 0;
    // border-bottom: 1px solid $borderColor;
    // border-radius: 0;
    // padding: 4.5px 0;

    // &:focus {
    //   outline: none;
    //   // box-shadow: 0;
    //   box-shadow: none;
    //   // border-bottom: 1px solid $borderColor;
    // }
  }

  & div.ant-picker {
    // border: 0;
    // border-bottom: 1px solid $borderColor;
    // border-radius: 0;
    // padding: 4.5px 0;
  }

  & div.ant-select-selector {
    // border: 0 !important;
    // border-bottom: 1px solid $borderColor !important;
    // border-radius: 0 !important;
    // padding-left: 0 !important;
    // outline: none !important;
    // // box-shadow: 0;
    // box-shadow: none !important;
  }

  // & div.ui.input {
  //     flex: 1;
  // }

  // & div.ui.input input {
  //     border: 0;
  //     border-bottom: 1px solid $borderColor;
  //     border-radius: 0;
  //     padding: 9px 0;
  // }
  // padding-bottom: 32px;

  // & .ui.selection.dropdown {
  //     border: 0;
  //     border-bottom: 1px solid $borderColor;
  //     border-radius: 0;

  // }

  // & .ui.selection.active.dropdown {
  //     border: 1px solid #96C8DA;
  //     border-radius: 0.28571429rem 0.28571429rem 0 0;
  // }

  // & .ui.selection.active.dropdown  {
  //     border-color: transparent;
  //     & .menu {
  //         border-color: transparent;
  //         &:hover {
  //             border-color: transparent;
  //         }
  //     }
  //     &:hover {
  //         border-color: transparent;
  //         & .menu {
  //             border-color: transparent;
  //         }
  //     }
  // }

  // End
}

.fadeIn {
  animation: fadeIn 0.4s ease-in;
}
.ant-modal-confirm .ant-modal-body {
  padding: 0px !important;
}
.ant-modal-confirm-body > .anticon {
  display: none;
}
.ant-modal-confirm .ant-modal-confirm-btns {
  padding: 10px;
  margin: 0px;
}
.ant-modal-confirm-body
  > .anticon
  + .ant-modal-confirm-title
  + .ant-modal-confirm-content,
.ant-modal-confirm-body .ant-modal-confirm-title {
  padding: 24px;
  margin: 0px;
  font-size: 1rem;
  border-bottom: 1px solid #f0f0f0;
  color: black;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
.ant-select-selection-overflow {
  // max-width: 307px !important;
  height: 100%;
  overflow: hidden !important;
  flex-wrap: nowrap !important;
}

.imageContainer {
  display: flex;
  position: relative;
  padding-bottom: 1.5rem;

  width: 100%;
  margin: 0 auto;
}

.imageContainerImage {
  & img {
    width: 100%;
    max-height: 12rem;
  }

  flex: 1;
  display: flex;
  flex-direction: column;
  padding-right: 1rem;
  &[dir="rtl"] {
    padding-right: 0;
    padding-left: 1rem;
  }
}

.isDisabled {
  color: red !important;
  cursor: all-scroll !important;
}


.ant-select-disabled{
.ant-select-selection-item-content{

    color: black !important
  }

}
.OTP_modal {
  width: 100%;
  display: flex;
  justify-content: center;
}
.OTP_input {
  width: 3rem !important;
  height: 3rem !important;
  color: black;
  font-size: 20px;
  
}


.multySelect_disabled {
  .ant-select-selector {
    background-color: #f5f5f5 !important;
  }
  .ant-select-selection-item {
    color: black !important;
    border-color: #d9d9d9 !important;
  }
}

.header{
      font-size: 1rem;
    font-weight: 600;
    color: black;
}
.datePicker {
  width: 100%;
  border: none;
  border: 1px solid #d9d9d9;
  color: black;
  background-color: white;
  // background-color: #f5f5f5;
  
  
  border-radius: 2px;
  line-height: 1.5715;
  font-size: 14px;
  outline: none;
  padding: 4px 11px;
}

.PhoneInput{
  input{
    border: none;
    outline: none;
  }
}
.custom-phoneInput{
  border: 1px solid #d9d9d9 !important;
  padding: 0.2rem 0.5rem;
}