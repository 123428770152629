.error-message {
    &__wrapper {
        display: flex;
        padding: 0.4rem;
        // position: absolute;
        // bottom: -4px;
    }

    &__text {
        font-size: 0.8rem;
        color: red;
    }
}