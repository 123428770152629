.wrapper {
    display: flex;
    flex-direction: column;
}

.content {
    flex: 1;
    & h3 {
        // text-align: center;
    }
    &:first-of-type {
        margin-top: 0;
    }
    margin-top: 2rem;
}

.footer {
    display: flex;
    justify-content: left;
    & button {
        margin: 0 0.5rem;
    }
}