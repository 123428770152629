@import "../shared/styles/main.scss";

.home {
  &__wrapper {
    display: flex;
    flex-direction: row;
    // height: 100%;
    min-height: 100%;
  }

  &__content-container {
    &[dir="rtl"] {
   
      padding-right: 80px;
      
  padding-left: 0;
  width: 100%;
  // height: calc(100vh - 3.5rem);
  // padding: 1rem;
  // background-color: $background;
  height: 100%;
  &>div {
    height: 100%;
    position: relative;
    &>div {
      margin-bottom: 24px;
    }
  }

    }
    &--active[dir="rtl"] {
      padding-right: 255.99px;
    }
    // margin-top: 5rem;
    padding-left: 80px;
    width: 100%;
    // height: calc(100vh - 3.5rem);
    // padding: 1rem;
    // background-color: $background;
    height: 100%;
    &>div {
      height: 100%;
      position: relative;
      &>div {
        margin-bottom: 24px;
      }
    }

    &--active {
      padding-left: 255.99px ;

    }

    transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

}

.custom-oyelabs-anim-enter {
  opacity: 0;
  // margin-top: 2.5rem;
  // margin-bottom: 0.2rem;
  transform: scale(0.97, 0.97);
  transition: all 400ms;
}

.custom-oyelabs-anim-enter-done {
  opacity: 1;
  transform: scale(1, 1);
  // margin-top: 0;
  // margin-bottom: 0;
  transition: all 400ms;
  
}

.custom-oyelabs-anim-enter.custom-oyelabs-anim-enter-active {
  // opacity: 1;
  // transform: scale(1);
  // // margin-top: 0;
  // // margin-bottom: 0;
  // transition: all 300;
  opacity: 0;
  // margin-top: 2.5rem;
  // margin-bottom: 0.2rem;
  transform: scale(0.98, 0.98);
  transition: all 400ms;
}

.custom-oyelabs-anim-exit {
  position: absolute;
  top: 0;
  // left: 256px;
  width: 100%;
  // right: 0;
  opacity: 1;
  transform: scale(1);
}

.custom-oyelabs-anim-exit.custom-oyelabs-anim-exit-active {
  opacity: 0;
  transition: all 400ms;
  transform: scaleX(1.013) scaleY(1.013)
}