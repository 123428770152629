@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap");
:root {
  --accent: #0d4387 !important;
}

$accent: #0d4387 !important;
$textPrimary: #6e7484;
$textSecondary: #929292;
$sidebarText: #515253;
$primary: #6e7484;
$primaryDark: rgb(62, 70, 73);
$primaryDarker: #191919;
$primaryLight: rgb(62, 70, 73);
$secondary: #efefef;
$secondaryDark: #c1c1c1;
$background: #f8f8f8;

$borderColor: #f0f0f0;
$highlight: #efefef;
$open: #6abf4b;

$shadow: 0 4px 24px 0 #22292f1a;
$inputplaceholdercolor: hsv(0, 0, 75%);

$shadowLow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
$shadowHigh: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
  0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12),
  0 16px 16px rgba(0, 0, 0, 0.12);
