@import "../../shared/styles/main.scss";

.dashboard {
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    // margin: 1rem;
    height: 100%;
    overflow: hidden;
  }
  &__filterCont {
    overflow: hidden;
    background-color: #fff;
    padding: 1.5rem;
    box-shadow: $shadow;
    border-radius: 0.428rem;
    margin-bottom: 28px;
    display: flex;
    justify-content: space-between;
  }
  &__FilterBtn {
    color: white;
    background-color: rgba(21, 96, 189, 1);
    padding: 0.3rem 1.5rem;
    font-weight: 500;
    border-radius: 6px;
    cursor: pointer;
    border: 0px !important;
  }

  &__content {
    display: flex;
    flex: 1;
    padding: 1rem 32px;
    padding-top: 0;
    flex-direction: column;
    // justify-content: space-evenly;
    align-items: flex-start;
    overflow: hidden;
    height: 100%;
  }

  &__charts-container {
    max-width: 1400px;
    width: 100%;
  }
  &__overview-container {
    display: flex;
    flex-wrap: wrap;
  }

  &__overview-card {
    &__wrapper {
      cursor: pointer;
      background-color: #fff;

      display: flex;
      border-radius: 0.428rem;
      justify-content: space-between;
      align-items: center;

      min-width: 130px;

      margin: 28px;
      margin-left: 0;
      margin-top: 0;
      box-shadow: 0 4px 24px 0 #22292f1a;
    }

    &__content {
      padding: 1rem;
      padding-left: 0;
      &[dir="rtl"] {
        padding-left: 1rem;
        padding-right: 0;
      }
    }

    &__count-header {
      color: #000;
    }

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      // border-radius: 100%;
      padding: 1rem;

      // border: 1px solid $borderColor;
      & svg {
        fill: $primary;
      }
    }

    &__footer {
      color: var(--accent);
      font-weight: bold;
      font-size: 16px;
      display: flex;
      justify-content: space-between;
      & section {
        & svg {
          fill: $primary;
        }
        display: flex;
        align-items: center;
        & i {
          margin: 0 5px;
        }
        font-size: 14px;
      }
    }
  }
}
