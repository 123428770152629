.background {
  background-image: url(${Term});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
}

.background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  pointer-events: none;
}

.terms-and-conditions {
  background-color: rgb(255, 255, 255);
  max-width: 1100px;
  margin: 50px;
  border-radius: 40px top;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: left;
  z-index: 1000;
}

h1 {
  font-size: 24px;
  margin-bottom: 50px;
  text-align: center;
}

h2 {
  font-size: 18px;
  margin-top: 30px;
}

p {
  font-size: 16px;
  line-height: 1.5;
}
