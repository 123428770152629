@import '../../../../shared/styles/main.scss';

.add-advertisement {

    &__image-container {
        display: flex;
        flex-direction: column;
        padding-bottom: 1.5rem;
        position: relative;
        & img {
            width: 360px;
            height: 240px;
        }
    }

    &__left-form {
        flex: 1;
        padding: 0.5rem;
    }

    &__right-form {
        flex: 1;
        padding: 0.5rem;
    }

    &__form {
        display: flex;
    }

    &__span-img {
        color: #ff0000;
    }
}