@import "../../../shared/styles/main.scss";
.pickers__add-edit-picker-modal {
  &__image-container {
    display: flex;
    position: relative;
    padding-bottom: 1.5rem;

    &__image {
    width: 300px;
    margin: 0 auto;
    }

    &__form {
      flex: 1;
    }
  }

  &__span-img {
    color: #ff0000;
  }
}
