@import "../shared/styles/main.scss";
.fp-component{
    &__wrapper {
      & .ant-tag {
        margin-top: 0.5rem;
      }
    }
    &__row {
      display: flex;
      justify-content: space-between;
    }
  
    &__label {
      font-weight: bold;
      color: $primary;
      margin-right: 2rem;
      // margin-top: 1rem;
    }
  
    &__value {
      color: $primaryDark;
    }
  }