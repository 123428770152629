@import "../../../shared/styles/main.scss";

.custom-upload-button {
  &__input {
    display: none;
  }

  &__label {
    text-align: center;
    background-color: $primary;
    color: #fff !important;
    transition: all 0.2s;
    cursor: pointer;
    padding: 0.2rem;
    display: flex;
    justify-content: center;
    &:hover {
      background-color: $primaryDark;
    }
  }
  &__loader {
    cursor: progress;
  }
  &__disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
    border: 1px solid #d9d9d9;
  }
}
