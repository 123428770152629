/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* App.css or App.less */
:root {
  --primary-color: #1890ff;
  /* Default Ant Design primary color */
  --background-color: #f8f8f8;
  /* Default background color */
}
body {
  background-color: var(--background-color);
}
.ant-btn-primary {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}
