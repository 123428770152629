@import '../../../../shared/styles/main.scss';


.stores-table {

    &__panel-container {
        min-height: 400px;
        // max-height: 400px;
    }

}