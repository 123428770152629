@import "./shared/styles/colors.scss";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap");
html {
  /* margin-left: calc(100vw - 100%); */
}

body {
  margin: 0;
  background-color: #f8f8f8 !important;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  font-family: "Montserrat", sans-serif;
}

:root {
  --accent: #1890ff !important;
  --accentBG: #1890ffb3 !important;
  --primary: #0d4387;
  --mainFont: "Montserrat";
  --alternateFont: "GeosansLight";
  --primary-color: #0d4387;
}

/* @font-face {
  font-family: "GeosansLight";
  src: url("./assets/fonts/GeosansLight.eot");
  src: local("GeosansLight"), local("GeosansLight"),
    url("./assets/fonts/GeosansLight.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/GeosansLight.woff2") format("woff2"),
    url("./assets/fonts/GeosansLight.woff") format("woff"),
    url("./assets/fonts/GeosansLight.ttf") format("truetype"),
    url("./assets/fonts/GeosansLight.svg#GeosansLight") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
} */
* {
  box-sizing: border-box;
  /* font-family: var(--mainFont); */
  font-family: "Montserrat", sans-serif;
}

.devButton {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999999999999;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.app-centered {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #efefef;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 10px 10px #fff; */
  border: 2px solid transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  /* box-shadow: inset 0 0 10px 10px #ababab; */
  box-shadow: inset 0 0 10px 10px rgb(215, 232, 253);
  border: 2px solid transparent;
  /* border: 2px solid transparent; */
  /* background-color: ; */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #efefef;
}
.ant-spin-dot-item {
  background-color: rgb(60, 179, 244) !important;
}
.ant-spin {
  color: rgb(60, 179, 244) !important;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: rgb(60, 179, 244) !important;
  border-color: rgb(60, 179, 244) !important;
}
.ant-table-thead .ant-table-cell {
  background-color: rgb(215, 232, 253) !important;
}
.ant-btn {
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out !important;
}

.ant-btn:hover {
  background-color: rgb(60, 179, 244) !important;
  color: black !important;
}
.ant-input-search-button {
  margin-left: 0;
  width: 300px;
}
