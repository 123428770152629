@import '../../../shared/styles/main.scss';

.custom-back-button {
    &__wrapper {
        display: flex !important;
        align-items: center;
        justify-content: center;
        margin: 1rem 0;
        padding-left: 0 !important;
        & svg {
            fill: $primaryDark;
        }
    }
}