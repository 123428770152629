@import "../../../shared/styles/main.scss";

.custom-panel {
  &__wrapper {
    // border: 1px solid $borderColor;
    // border-top: 3px solid $borderColor;
    // padding: 0.5rem;
    // background-color: #fff;
    // border-radius: 4px;
    position: relative;
  }

  &__modal-loader {
    opacity: 0.5;
    pointer-events: none;
    transition: all 0.2s;
  }
  &__dimmer {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    // background-color: rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }

  &__blurred-container {
    background-size:cover;
-webkit-filter: blur(4px);
-moz-filter: blur(4px);
-ms-filter: blur(4px);
-o-filter: blur(4px);
filter: blur(4px);
transition: filter 0.5s;
  }

  &__container-class {
    overflow-x: auto;
  }

  &__empty-state-container {
    & svg {
      fill: $primaryDark;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--accent);
  }
}

  