@import '../../../../shared/styles/main.scss';

.order-reports__chart-container {
    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        
    }

    &__header {
        color: var(--accent);
        font-size: 1.4rem;
        font-weight: bold;
        margin-bottom: 1rem;
    }

    &__footer {
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    &__chart-item-wrapper {
        margin-top: 1rem;
        padding: 1rem;
        overflow: hidden;
        background-color: #fff;
        border: 1px solid $borderColor;
        flex: 2;
    }

    &__pie-chart-item-wrapper {
        margin-top: 1rem;
        &.custom-section {
            margin-right: 0;
        }
        padding: 1rem;
        background-color: #fff;
        border: 1px solid $borderColor;
        flex: 1;
    }

    &__chart-item {
        overflow: hidden;
        display: flex;
        // margin-bottom: 1rem;
    }

    &__pie-chart-item {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    @media (min-width: 768px) {
        
    }
}

