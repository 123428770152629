@import "../../../shared/styles/main.scss";
// .react-ios-time-picker-input {
//   width: 100% !important;
//    &.react-ios-time-picker-input-disabled {
//     cursor: not-allowed;
//     background-color: #f5f5f5 !important;
//     font-weight: 500;
//   }
// }
// .react-ios-time-picker-input.react-ios-time-picker-input-disabled {
//     cursor: not-allowed;
//     background-color: #f5f5f5 !important;
//     font-weight: 500;
//   }
.fileUpload{
    display: flex;
    gap: 1rem;
    align-items: flex-end;
    border: 1px solid;
    width: 100%;
}