@import '../../shared/styles/main.scss';

.advertisements {
    &__wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    &__menu {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem 0;
    }
}