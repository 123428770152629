.custom-table {
  & .ant-table-thead {
    & .ant-table-cell {
      background-color: #2654b1;
      border-bottom: 2px solid #ebe9f1;
      border-top: 1px solid #ebe9f1;
      text-align: center;
    }
  }

  & .ant-table-tbody {
    & .ant-table-cell {
      text-align: center;
      // padding: 0.72rem 2rem !important;
      font-size: 14px;
    }
  }

  & .ant-table-pagination.ant-pagination {
    margin-right: 26px;
  }
}

.disabled-antd-row {
  & > td {
    pointer-events: none;
  }
}
