/* CheckoutSuccess.css */
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-image: url("../../assets/images/card-success.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
}

.white-box {
  position: relative;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.538);

  /* Added padding for mobile devices */
  @media screen and (max-width: 480px) {
    padding: 20px 10px;
  }
}

.image {
  max-width: 30%;
  margin-bottom: 20px;
}

.text {
  font-size: 15px;
  font-weight: bold;
  color: #000000;
}
.red-cross {
  font-size: 20px;
  color: #f5222d;
}
.green-tick {
  font-size: 20px;
  color: #52c41a;
}

@media screen and (max-width: 768px) {
  .white-box {
    margin-left: 20px;
    margin-right: 20px;
  }
  .image {
    max-width: 50%;
  }

  .text {
    font-size: 18px;
  }

  .green-tick {
    font-size: 24px;
  }
}

@media screen and (max-width: 480px) {
  .white-box {
    margin-left: 20px;
    margin-right: 20px;
  }
  .image {
    max-width: 60%;
  }

  .text {
    font-size: 16px;
  }

  .green-tick {
    font-size: 28px;
  }
}
