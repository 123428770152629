.ant-input::placeholder {
  color: #787575 !important;
}
.ant-select-selection-placeholder {
  color: #787575 !important;
}
.react-datepicker__input-container input::placeholder {
  color: #787575;
}

// .ant-btn-primary {
//   background-color: #2668b9 !important;
//   border: 0px !important;
// }
.ant-radio-inner::after {
  background-color: #0d4387 !important;
}
/* Change the placeholder color of all Ant Design DatePicker components */
.ant-picker-input > input::placeholder {
  color: #787575 !important;
}
// .ant-input-search-button {
//   margin-left: 0;
//   visibility: hidden;
//   background-color: white;
// }
