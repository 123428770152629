@import '../../../shared/styles/main.scss';

.custom-label {
    &__label {
        padding: 0 0 8px;
        line-height: 1.5715;
        white-space: normal;
        font-size: 14px;
        white-space: nowrap;
        &--horizontal {
            padding: 0;
            padding-right: 8px;
            &[dir="rtl"] {
                padding-right: 0;
                padding-left: 8px;
            }
        } 
        &--vertical {
           display: flex;
          
        }
    }
}