@import "../../../shared/styles/main.scss";

.custom-modal {
  &__wrapper {
    position: relative;
    // overflow: hidden;
  }
&__action {
  display: flex;
  // flex-direction: row-reverse;
  justify-content: flex-end;
  & button {
    margin-left: 1rem;
  }

  &[dir="rtl"] {
    justify-content: flex-start;
    flex-direction: row-reverse;
  }
}

&__header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
  // &__header {
  //   padding: 0.64rem 2rem;
  //   background-color: #f9f9f9;
  //   font-size: 1.5rem;
  //   color: var(--accent);
  //   border-top-left-radius: 1rem;
  //   border-top-right-radius: 1rem;
  //   text-overflow: ellipsis;
  //   overflow-x: hidden;
  //   white-space: nowrap;
  //   &::after {
  //     content: "";
  //     width: 57px;
  //     height: 57px;
  //     clip-path: polygon(100% 0, 0 0, 100% 100%);
  //     border-top-right-radius: 1rem;
  //     background-color: var(--accent);
  //     right: 0;
  //     top: 0;
  //     position: absolute;
  //   }
  // }

  &__content-container {
    // padding: 1.2rem 2rem;
  }

  &__ant-modal-wrapper {
    & .ant-drawer-content-wrapper {
      // border-radius: 1rem;
      & .ant-drawer-close {
        // display: none;
      }
    }
  }

  &__close-icon-wrapper {
    // top: 0px;
    // right: 8px;
    // z-index: 2;
    // position: absolute;
    // color: white;
    // cursor: pointer;
  }
}

#custom-modal-body[dir="rtl"] {
  & .custom-form-group, .custom-form-item {
    padding-right: 0;
    padding-left: 1rem;
  }
}
