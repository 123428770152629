@import "../../shared/styles/main.scss";

.customers {
  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  &__menu {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    align-items: center;

    &__records-per-page {
      & label {
        color: var(--accent);
        font-weight: normal;
        margin-right: 0.5rem;
      }
    }
  }
}
.VerifiedEmail_cont {
  width: 300px;
}
.filterCont {
  display: flex;
  gap: 1rem;
  padding: 1rem 1.3rem;
  margin: 0 2rem;
  box-shadow: $shadow;
  border-radius: 0.428rem;
  background: white;
  margin-bottom: 1rem;
}
