@import "../../../../shared/styles/main.scss";

.add-edit-product {
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  &__form-container {
    flex: 2;
    display: flex;
    flex-direction: column;
    padding-right: 1rem;
  }
}

.carousel .slide .legend.add-edit-product__delete-button {
  background-color: var(--accent);
  color: white;
  cursor: pointer;
}
.questionaryTableHead {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.addOptionButton {
  color: "#1890ff";
  font-size: "1.5rem";
  margin-bottom: "1rem";
}
.deleteOptionButton {
  color: "red";
  font-size: "1.5rem";
  margin-bottom: "1rem";
}
