@import '../../shared/styles/main.scss';

.products {
    &__wrapper {
        display: flex;
        flex-direction: column;
    }

    &__upper-menu {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1.5rem;
        align-items: center;

        &__left {
            display: flex;
        }

        &__dropdown {
            margin-right: 1rem;
        }

        &__right {

        }

        &__records-per-page {
            margin-right: 1rem;
            & label {
                color: var(--accent);
                font-weight: normal;
                margin-right: 0.5rem;
            }
        }
        
    }

    &__lower-menu {
        display: flex;
        justify-content: space-between;
        &__left {
            display: flex;
            flex: 2;
            flex-wrap: wrap;
            white-space: nowrap;
        }
        
        &__dropdown {
            display: flex;
            flex-direction: column;
            margin-right: 1rem;
            flex: 1;
            & label {
                color: var(--accent);
                font-weight: normal;
            }
        }

        &__right {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }
}