@import "../../../../shared/styles/main.scss";
.products-table {
  &__wrapper {
    margin-top: 1rem;
  }

  &__panel-container {
    // min-height: 300px;
    height: 100%;
    // max-height: 400px;
  }

  &__product-details {
    &__wrapper {
      display: flex;
    }

    &__image {
      width: 50px;
      height: 50px;
    }

    &__info {
      display: flex;
      flex-direction: column;
      padding: 0.5rem;
    }

    &__name {
      white-space: nowrap;
      text-overflow: ellipsis;
      // max-width: 14rem;
      overflow: hidden;
      font-size: 0.9rem;
      color: var(--accent);
      font-weight: bold;
    }

    &__description {
      // white-space: nowrap;
      text-overflow: ellipsis;
      // max-width: 14rem;
      overflow: hidden;
      font-size: 0.9rem;
      color: $primaryDark;
    }
  }

  &__table-actions {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__action-icon {
    margin-right: 1rem;
    height: 18px;
    &:hover {
      cursor: pointer;
    }
    & svg {
      fill: $primaryDark;
    }
    &:last-of-type {
      margin-right: 0;
    }

    &--hidden {
      visibility: hidden;
    }
  }

  &__sticky-right {
    position: sticky;
    right: 0;
    box-shadow: $shadowLow;
  }
}
td.products-table__sticky-right {
  background-color: #fff;
  // box-shadow: $shadowLow;
}
