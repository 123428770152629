@import "../../shared/styles/main.scss";

.active-promo {
  &__wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;

    margin-bottom: 2rem;
  }

  &__content {
    display: flex;
    justify-content: space-around;
  }

  &__menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    &--left {
      display: flex;
    }

    &--right {
      & button {
        margin-right: 1rem;
      }

      & button:last-of-type {
        margin-right: 0;
      }
    }
  }

  &__records-per-page {
    & label {
      // color: var(--accent);
      font-weight: normal;
      margin-right: 0.5rem;
    }
  }
}
.element-distance {
  margin-top: 10px;
}
