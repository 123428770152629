@import "../../shared/styles/main.scss";
.ant-radio-inner::after {
  background-color: #0d4387;
}
.login {
  &__wrapper {
    background-color: #fff;
    border-radius: 1rem;
    box-shadow: $shadowLow;
    display: flex;

    flex-direction: column;
  }
  &__container {
  }

  &__logo-image {
    // padding: 1rem;
    max-width: 80%;
    height: 100px;
    margin: 10px;
  }

  &__logo-container {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    text-align: center;
    background-color: #ffffff;
  }
  .welcomeTitle {
    // color: #0d4387 !important;
    // font-weight: 400;
    // font-size: 28px;
    // margin-top: 2rem;
    // margin-bottom: 2rem;
  }
  &__welcome-container {
    text-align: center;
    color: #0d4387 !important;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-weight: 400;
    &.welcomeTitle {
      margin: 0;
      font-weight: 400;
      font-weight: normal;
      color: $primary;
      margin-bottom: 0.2rem;
      letter-spacing: 2px;
    }

    &__sub-title {
      margin: 0;
      font-weight: normal;
      color: var(--accent);
      letter-spacing: 1.25px;
    }
  }

  &__form-container {
    padding: 3rem 9rem;
    padding-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__login-button {
    margin: 0 auto !important;
    margin-top: 1.7rem !important;
    width: 75%;
    max-width: 20rem;
    background: linear-gradient(to right, #3f3fd4, #53bbe4) !important;
    border-radius: 6px !important;
    border: 0px !important;
  }
  .pass {
    color: #3f3fd4 !important;
  }

  &__form-item {
    padding-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    position: relative;

    &__password {
      position: relative;
      & > i {
        position: absolute;
        right: 5px;
      }
      & svg {
        fill: $primary;
      }
    }

    & input {
      border: 0;
      border-bottom: 1px solid $borderColor;
      border-radius: 0;

      &:focus {
        outline: none;
        box-shadow: 0;
        box-shadow: none;
        border-bottom: 1px solid $borderColor;
      }
    }
  }
}

@media only screen and (max-width: 786px) {
  .login {
    &__form-container {
      padding: 3rem 4rem;
    }
  }
}
