@import "../../../../shared/styles/main.scss";
.active-promo__add-edit-promo-modal {
  &__image-container {
    display: flex;
    position: relative;
    padding-bottom: 1.5rem;

    width: 50%;
    margin: 0 auto;

    &__image {
      & img {
        width: 100%;
        max-height: 12rem;
      }

      flex: 1;
      display: flex;
      flex-direction: column;
      padding-right: 1rem;
      &[dir="rtl"] {
        padding-right: 0;
        padding-left: 1rem;
      }
    }

    &__form {
      flex: 1;
    }
  }

  &__span-img {
    color: #ff0000;
  }

  .ant-select-selection-placeholder {
    color: black !important;
  }
  .ant-select-selection-item {
    color: black !important;
  }
  .ant-input-disabled {
    color: black !important;
  }
}
