@import "../../../../shared/styles/main.scss";
.Questionary_add_edit_modal {
  

  &__span-img {
    color: #ff0000;
  }
  

}


