@import '../../../shared/styles/main.scss';

.add-edit-product {
    &__wrapper {
        display: flex;
        flex-wrap: wrap;
    }

    &__form-container {
        flex: 2;
        display: flex;
        flex-direction: column;
        padding-right: 1rem;
    }

    &__image-container {
        width: 70%;
        margin: 0 auto;
        flex: 1;
        display: flex;
        flex-direction: column;
        & img{
            width: 100%;
        }
    }

}

.carousel .slide .legend.add-edit-product__delete-button {
    background-color: var(--accent);
    color: white;
    cursor: pointer;
}
