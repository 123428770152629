@import "../../shared/styles/main.scss";

.oyelabs__large-inv__admin-settings {
  &__wrapper {
    // height: 100%;
    display: flex;
    flex-direction: column;
    min-height: 90vh;
    // margin-bottom: 2rem;
  }

  &__content {
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid $borderColor;
    padding: 1rem;
  }

  &__image-item {
    // max-width: 250px !important;
    display: flex;

    gap: 1rem;
    align-items: center;

    &.sidebar {
      & img {
        width: 400px;
        height: 100px;
      }
    }

    &.favIco {
      max-width: 150px;
      & img {
        // width: 100px;
        height: 100px;
      }
    }
  }

  &__form-header {
    font-size: 1.1rem;
    margin-bottom: 1rem;
  }
  &__form-wrapper {
    margin-bottom: 1rem;
  }
  &__form-wrapper .custom-form-item {
    max-width: 22rem;
  }

  &__content {
  }
}
/* In your styles.css or similar stylesheet */

/* Styling for the microphone icon */
.microphone-icon {
  margin-left: auto;
  color: #555; /* Default color */
  transition: color 0.3s; /* Smooth color transition */
  width: 20px;
  height: 30px;
}

/* Animation for the active state */
.microphone-icon.active {
  animation: pulse 2s infinite; /* Apply the pulse animation */
  /* Change color for active state */
}

/* Keyframes for the pulse animation */
@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
    color: #4285f4; /* First color */
  }
  25% {
    transform: scale(1.1);
    color: #db4437; /* Second color */
  }
  50% {
    transform: scale(1);
    color: #f4b400; /* Third color */
  }
  75% {
    transform: scale(1.1);
    color: #0f9d58; /* Fourth color */
  }
}
