@import "../../../../shared/styles/main.scss";

.customer-details {
  &__wrapper {
    display: flex;
    flex-direction: column;
  }
  .detailsField {
    font-weight: 400;
    font-size: xx-large;
  }
  &__back-button {
    display: flex;
    justify-content: flex-start;
  }

  &__menu {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    align-items: center;

    &__records-per-page {
      & label {
        color: var(--accent);
        font-weight: normal;
        margin-right: 0.5rem;
      }
    }
  }

  &__form-wrapper {
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
    border: 1px solid $borderColor;
    padding: 1rem;
    position: relative;
    margin-bottom: 1rem;
  }

  &__image {
    & img {
      width: 100%;

      min-width: 200px;

      height: 200px;
    }
    // flex: 1;
  }

  &__form {
    flex: 3;
    padding-top: 1rem;
    padding-left: 1rem;
  }

  &__edit-icon {
    position: absolute;
    top: 10px;
    right: 10px;

    & svg {
      fill: $primaryDark;
    }
  }
}
